import PropTypes from "prop-types";
import { Avatar } from "@mui/material";

const FailSafeAvatar = ({ firstName = null, lastName = null, profilePicURL = null, sz = 40, sx }) => {

    const lastNameLetter = lastName && lastName.length > 0 ? lastName.charAt(0).toUpperCase() : "";
    const initials =
        firstName && firstName.length > 0 && lastName && lastName.length > 0
            ? `${firstName.charAt(0).toUpperCase()}${lastNameLetter}`
            : firstName && firstName.length > 0 ? firstName.charAt(0).toUpperCase() : null; // no initials

    const alt = !!firstName ? `${firstName}${lastName ? " " + lastName : ""}` : initials ?? "User";

    const basePhotoURL = profilePicURL ?? "https://ionicframework.com/docs/img/demos/avatar.svg";
    const photoName = basePhotoURL.substring(basePhotoURL.lastIndexOf('/') + 1);
    const photoURLPrefix = basePhotoURL.substring(0, basePhotoURL.lastIndexOf('/') + 1);

    const thumbnailURL = `${photoURLPrefix}thumbnail_${photoName}`;
    const smallURL = `${photoURLPrefix}small_${photoName}`;
    const mediumURL = `${photoURLPrefix}medium_${photoName}`;
    const largeURL = `${photoURLPrefix}large_${photoName}`;

    const srcURL = sz <= 50 ? thumbnailURL : sz <= 100 ? smallURL : sz <= 200 ? mediumURL : largeURL;

    return (
        <Avatar
            alt={alt}
            src={srcURL}
            sx={{ width: `${sz}px`, height: `${sz}px`, ...sx }}
        >
            <Avatar
                alt={alt}
                src={initials && basePhotoURL?.includes("ionicframework.com") ? null : basePhotoURL}
                sx={{ width: `${sz}px`, height: `${sz}px`, ...sx }}
            >
                {initials ? (<h5>{initials}</h5>) : (<h5>AMA!</h5>)}
            </Avatar>
        </Avatar>
    );
};

FailSafeAvatar.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePicURL: PropTypes.string,
    sz: PropTypes.number,
};

export default FailSafeAvatar;
