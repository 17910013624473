import PropTypes from 'prop-types';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { ChatMessage } from './chat-message';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useAuth } from 'src/hooks/use-auth';
import FailSafeAvatar from 'src/pages/components/fail-safe-avatar';
import aiBotIcon from 'src/icons/ai-bot.png';

const getAuthor = (message, ambassadors, user) => {
    // Since chat mock db is not synced with external auth providers
    // we set the user details from user auth state instead of thread participants
    if (message.authorId === user?.id) {
        return {
            name: 'Me',
            avatar: user.photoURL,
            isUser: true
        };
    }

    if (message.authorId === 'aiChat') {
        return {
            name: 'AI Bot',
            avatar: aiBotIcon,
            isUser: false
        };
    }

    const ambassador = ambassadors.byId[message.authorId];

    if (!ambassador) {
        return {
            name: 'Former Ambassador',
            avatar: '',
            isUser: false
        };
    }

    return {
        avatar: ambassador.photoURL,
        name: ambassador.firstName,
        isUser: false
    };
};

export const ChatMessages = (props) => {
    const { onTabChange, onTurnNotificationsOn, isAiChat, onAiChat, onEdit, onReply, messages, ambassadors, ambassadorName, activeThreadID, contributorsAddedDates, ...other } = props;
    const { user } = useAuth();

    const sortedMessages = messages.sort((a, b) => a.createdAt - b.createdAt);

    // Keep track of which "contributor added to chat" messages have been shown
    // via a map with keys the same as contributorsAddedDates and boolean values
    // indicating whether the message has been shown
    const contributorsAddedMessagesShown = Object.keys(contributorsAddedDates ?? {}).reduce((acc, key) => {
        acc[key] = null;
        return acc;
    }, {}) ?? {};

    const messagesReverseSorted = sortedMessages.slice().reverse();

    for (let i = 0; i < messagesReverseSorted.length; i++) {
        const message = messagesReverseSorted[i];
        const contributorsAddedToChat = Object.keys(contributorsAddedDates ?? {}).filter((contributorId) => {
            if (contributorsAddedMessagesShown[contributorId]) {
                return false;
            }

            if (contributorsAddedDates[contributorId].toMillis() > message.createdAt) {
                return true;
            }
        });

        contributorsAddedToChat.forEach((contributorId) => {
            contributorsAddedMessagesShown[contributorId] = message.id;
        });
    }

    return (
        <Stack
            spacing={2}
            sx={{ p: 3 }}
            {...other}>
            {sortedMessages.map((message) => {
                const author = getAuthor(message, ambassadors, user);

                const replyMessage = !!message.replyMessage ? messages.find((m) => m.id === message.replyMessage.id) : null;
                const replyMessageSender = replyMessage ? getAuthor(replyMessage, ambassadors, user) : null;

                return (
                    <Stack key={message.id}>
                        <ChatMessage
                            id={message.id}
                            isAiChat={isAiChat}
                            onAiChat={onAiChat}
                            onTabChange={onTabChange}
                            activeThreadID={activeThreadID}
                            onReply={onReply}
                            onEdit={onEdit}
                            authorName={author.name}
                            body={message.body}
                            contentType={message.contentType}
                            createdAt={message.createdAt}
                            position={author.isUser ? 'right' : 'left'}
                            replyMessageSender={replyMessageSender?.name ?? null}
                            replyMessageContent={replyMessage?.body ?? null}
                            reactions={message.reactions}
                            ambassadorName={ambassadorName}
                            isEdited={message.isEdited}
                            isUser={author.isUser}
                            avatar={author.avatar}
                            onTurnNotificationsOn={onTurnNotificationsOn}
                        />
                        {Object.keys(contributorsAddedMessagesShown).filter((contributorId) => contributorsAddedMessagesShown[contributorId] === message.id).map((contributorId) => {
                            const contributor = ambassadors.byId[contributorId];

                            return (
                                <Box key={`contributor_added_${message.id}_${contributorId}`} sx={{ mb: 1, display: "flex", flexDirection: "row", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                                    <FailSafeAvatar
                                        profilePicURL={contributor.photoURL}
                                        sz={25}
                                        sx={{ marginRight: 1 }}
                                    />
                                    <Typography
                                        color="text.secondary"
                                        sx={{ display: "block" }}
                                        variant="subtitle2"
                                    >
                                        {contributor.firstName} was added to the chat
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Stack>
                );
            })}
        </Stack>
    );
};

ChatMessages.propTypes = {
    onTabChange: PropTypes.func,
    onTurnNotificationsOn: PropTypes.func,
    isAiChat: PropTypes.bool,
    onAiChat: PropTypes.func,
    activeThreadID: PropTypes.string,
    onReply: PropTypes.func,
    onEdit: PropTypes.func,
    messages: PropTypes.array,
    ambassadors: PropTypes.object,
    ambassadorName: PropTypes.string,
    contributorsAddedDates: PropTypes.object,
};
